<div class="container text-center px-5 py-3">
    <div class="m-auto mt-1">
        <img [src]="host + '/' + logo" style="width:5rem;" /><br>
        <div class="m-3">
            <h2 class="display">{{ 'register.sign_up' | translate }}</h2>
        </div>

        <div style="overflow-y: scroll;">
        <form [formGroup]="registerForm" (ngSubmit)="register()" novalidate *ngIf="showForm">
            <div class="mb-3">
                <label for="name" class="form-label">{{ 'register.name' | translate }}</label>
                <input type="text" class="form-control" id="name" formControlName="name" required>
                <div
                    *ngIf="registerForm.get('name')?.invalid && (registerForm.get('name')?.dirty || registerForm.get('name')?.touched)" class="text-danger">
                    {{ 'register.name_required' | translate }}
                </div>
            </div>
            <div class="mb-3">
                <label for="username" class="form-label">{{ 'register.username' | translate }}</label>
                <input type="text" class="form-control" id="username" formControlName="username" required>
                <div *ngIf="registerForm.get('username')?.invalid && (registerForm.get('username')?.dirty || registerForm.get('username')?.touched)"
                    class="text-danger">
                    {{ 'register.username_required' | translate }}
                </div>
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">{{ 'register.email' | translate }}</label>
                <input type="email" class="form-control" id="email" formControlName="email" required>
                <div *ngIf="registerForm.get('email')?.invalid && (registerForm.get('email')?.dirty || registerForm.get('email')?.touched)"
                    class="text-danger">
                    <div *ngIf="registerForm.get('email')?.errors?.['required']">
                        {{ 'register.email_required' | translate }}
                    </div>
                    <div *ngIf="registerForm.get('email')?.errors?.['email']">
                        {{ 'register.invalid_email' | translate }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">{{ 'register.password' | translate }}</label>
                <input type="password" class="form-control" id="password" formControlName="password" required>
                <div *ngIf="registerForm.get('password')?.invalid && (registerForm.get('password')?.dirty || registerForm.get('password')?.touched)"
                    class="text-danger">
                    {{ 'register.password_required' | translate }}
                </div>
            </div>
            <div class="mb-3">
                <label for="confirmPassword" class="form-label">{{ 'register.confirm_password' | translate }}</label>
                <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword"
                    required>
                <div *ngIf="registerForm.get('confirmPassword')?.invalid && (registerForm.get('confirmPassword')?.dirty || registerForm.get('confirmPassword')?.touched)"
                    class="text-danger">
                    {{ 'register.confirm_password_required' | translate }}
                </div>
                <div *ngIf="registerForm.get('password')?.value !== registerForm.get('confirmPassword')?.value">
                    {{ 'register.passwords_do_not_match' | translate }}
                </div>
            </div>
            <p *ngIf="errorMessage" class="my-3 text-danger">{{ errorMessage }}</p>
            <button type="submit" class="btn btn-primary w-100 mt-3 px-5 fw-bold">{{ 'register.sign_up' | translate
                }}</button>
        </form>
    </div>
        <div *ngIf="!showForm">
            <div class="alert alert-success" style="border-radius: unset;" role="alert">
                <i class="bi bi-check-circle-fill me-3 fs-5"></i>
                <strong>{{ successMessage }}</strong>
            </div>
            <p class="mt-4">
                <a routerLink="/login"
                    class="register-link text-white-50 link link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
                    {{ 'register.back_to_login' | translate }}
                </a>
            </p>
        </div>
    </div>
</div>