import { Component, OnInit } from '@angular/core';
import packageInfo from '../../package.json';
import { TranslateService } from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
import { ChildrenOutletContexts } from '@angular/router';
import { slider } from './app-routing-animation';
import { Router } from '@angular/router';
import { Event, NavigationEnd } from '@angular/router';
import { IConstants } from './IConstants';
import { AutoSyncService } from './service/auto-sync.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
     slider
  ]
})
export class AppComponent implements OnInit {
  title = 'ticket-scanner';
  app_version = packageInfo.version;
  softwareUpdateAvailable: boolean = false;

  networkStatus?: OnlineStatusType;

  currentRoute: string = "";

  excludeScreenRoutes = ['', '/','login', '/login', 'register', '/register'];

  constructor(private contexts: ChildrenOutletContexts, private router: Router, private readonly softwareUpdateService: SwUpdate, public translate: TranslateService, private onlineStatusService: OnlineStatusService, private autoSync: AutoSyncService) {
     this.translate.addLangs(['en', 'hu']);
     // this language will be used as a fallback when a translation isn't found in the current language
     this.translate.setDefaultLang('en');
     this.translate.use((localStorage.getItem(IConstants.CONSTANT_LANGUAGE_STORAGE_NAME) || 'en').toLowerCase());

     this.autoSync.ngOnInit();
  }

  ngOnInit(): void {

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
          this.currentRoute = event.url;
      }

    });


    this.softwareUpdateService.checkForUpdate().then((data) => {
        console.log("Update found: "+data);
        this.softwareUpdateAvailable = data;
    });

    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      // Retrieve Online status Type
      this.networkStatus = status;
    });

  }

  public update(){
    this.softwareUpdateService.activateUpdate().then(() => document.location.reload());
  }

  public switchLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem(IConstants.CONSTANT_LANGUAGE_STORAGE_NAME, lang.toLowerCase());
  }


  public getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

}
