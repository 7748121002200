import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../service/user.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../entity/User';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  host!: string;
  logo!: string;
  showForm: boolean = true;
  errorMessage!: string;
  successMessage!: string;
  registerForm!: FormGroup;

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    this.host = environment.HOST;
    this.logo = environment.BRANDING_LOGO;
  }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      name: [null, Validators.required],
      username: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required]
    });
  }

  public register() {
    const { name, username, email, password, confirmPassword } = this.registerForm.value;

    this.errorMessage = '';
    this.successMessage = '';

    if (password !== confirmPassword) {
      this.errorMessage = this.translate.instant("register.passwords_do_not_match");
      return;
    }

    const user: User = {
      name,
      username,
      email,
      password
    } as User;

    this.userService.register(user)
      .subscribe(response => {
        if (response) {
          this.successMessage = this.translate.instant("register.registration_success");
          this.showForm = false;
          this.registerForm.reset(); 
        } else {
          this.errorMessage = this.translate.instant("register.registration_failed");
        }
      });
  }

  get f() {
    return this.registerForm.controls;
  }
}
