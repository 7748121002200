import { Injectable } from '@angular/core';
import { User } from '../entity/User';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, retry, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { IConstants } from '../IConstants';

const API_ENDPOINT = "auth";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  login(user: User): Observable<User | null> {
    return this.http.post<User>(`${environment.HOST}${environment.API_PATH_PREFIX}${API_ENDPOINT}`, user)
      .pipe(
        retry(2),
        tap((loggedInUser: User) => {
          if (loggedInUser.role.rights.includes('admin_area')) {
            localStorage.setItem(IConstants.CONSTANT_AUTH_STORAGE_NAME, JSON.stringify(loggedInUser));
          }
        }),
        catchError(error => {
          console.error('Login error:', error);
          return of(null);
        })
      );
  }

  register(user: User): Observable<User | null> {
    return this.http.post<any>(`${environment.HOST}${environment.API_PATH_PREFIX}register`, user)
      .pipe(
        catchError(error => {
          console.error('Registration error:', error);
          return of(null);
        })
      );
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem(IConstants.CONSTANT_AUTH_STORAGE_NAME) || '{}') as User;
  }

  isLoggedIn(): boolean {
    return localStorage.getItem(IConstants.CONSTANT_AUTH_STORAGE_NAME) !== null;
  }

  logout(): void {
    localStorage.removeItem(IConstants.CONSTANT_AUTH_STORAGE_NAME);
  }
}