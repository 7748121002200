import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { RegisterComponent } from './view/register/register.component';
import { ScannerComponent } from './view/scanner/scanner.component';
import { MenuComponent } from './view/menu/menu.component';
import { OptionsComponent } from './view/options/options.component';
import { EventsComponent } from './view/events/events.component';
import { CheckTicketComponent } from './view/check-ticket/check-ticket.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent, data: { animation: 'isRight' } },
  { path: 'menu', component: MenuComponent, canActivate: [AuthGuard] },
  { path: 'scanner', component: ScannerComponent, canActivate: [AuthGuard] },
  { path: 'check', component: CheckTicketComponent, canActivate: [AuthGuard], data: { animation: 'isRight' } },
  { path: 'options', component: OptionsComponent, canActivate: [AuthGuard], data: { animation: 'isRight' } },
  { path: 'events', component: EventsComponent, canActivate: [AuthGuard], data: { animation: 'isRight' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
